import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const Heading = ({ heading = false }) => {
  return (
    heading && (
      <HeadingWrapper id="heading">
        <HeadingContent>{ReactHtmlParser(heading)}</HeadingContent>
      </HeadingWrapper>
    )
  );
};

// ======== HEADING ======== //

const HeadingWrapper = styled.div`
  background: #ec1300;
  padding: 25px 15px;
`;

const HeadingContent = styled.h3`
  color: #ffffff;
  font-size: clamp(24px, 3vw, 32px);
  text-align: center;
  margin: 0;
`;
