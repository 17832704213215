import React, { useState, useReducer, useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import md5 from "blueimp-md5";
import {
  FormMessage,
  handleErrors,
  firebase,
  EventContext,
  AnalyticsContext,
} from "Components";
import { reducer } from "Modules";
import { FormFields } from "./FormFields";
import { fonts } from "theme/fonts";

export const RegistrationForm = ({
  formPart,
  formLegal,
  onSuccess,
  submitButton,
}) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { event } = useContext(EventContext);
  const { logLeadSubmission, setUserProperties } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();
  const [emailMsg, setEmailMsg] = useState(null);

  const ageGateMessage = event.register.ageGateMessage;
  const formHeading = event.register.formHeading;
  const fields = event.register.fields;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const verifyEmail = async (email) => {
    if (!email) {
      return false;
    }

    const hash = md5(email.toLowerCase());

    try {
      const doc = await firebase
        .firestore()
        .doc(`events/${event.id}/verifiedEmails/${hash}`)
        .get();

      return doc.exists;
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration, formPart, event.id);

    if (!errorObject) {
      setSubmitting(true);

      const verified = await verifyEmail(registration.EMAIL);

      try {
        if (verified === true) {
          firebase
            .firestore()
            .collection(`events/${process.env.REACT_APP_EVENT_ID}/lead_submits`)
            .add({
              ...registration,
              email: registration.EMAIL,
            })
            .then((ref) => {
              ref.onSnapshot((doc) => {
                const { token } = doc.data();
                if (token) {
                  onSuccess(false, token, formPart);
                  setSubmitting(false);
                  logLeadSubmission({ formPart: 1 });
                  setUserProperties({ token });
                }
              });
            });
        } else {
          setSubmitting(false);
          setEmailMsg(event.register.errorMessages.invalidEmail);
        }
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{formHeading}</FormTitle>}

      {submitting ? (
        <h4>Loading...</h4>
      ) : (
        <>
          {emailMsg && <ErrorMessage>{emailMsg}</ErrorMessage>}

          <FormFields
            fields={fields}
            formPart={formPart}
            handleChange={handleChange}
            errors={errors}
            registration={registration}
            formLegal={formLegal}
          />

          <FormMessage
            active={!!errors?.AGE_GATE}
            content={ageGateMessage}
            style={{ order: 6 }}
          />

          <RegisterButton onClick={handleSubmit}>
            {submitButton ? submitButton : "Submit"}
          </RegisterButton>
        </>
      )}
    </>
  );
};

const FormTitle = styled.h3`
  color: #ffffff;
`;

const RegisterButton = styled.button`
  margin-top: 24px;
  background-color: #d54400;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  outline: 0;
  border: 0;

  &:hover,
  &:focus {
    background-color: #ffffff;
    color: #d54400;
  }
`;

const ErrorMessage = styled.h4`
  color: #d54400;
  font-family: ${fonts.default};
  margin-bottom: 10px;
  font-weight: 300px;
`;
