import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout } from "../Layout";
import styled from "styled-components";
import { PostShowSurvey, Hero } from "Components";
import { fonts } from "theme/fonts";

export const PostShow = ({ event, token, onSuccess }) => {
  return (
    <Layout
      // header
      showHeader
      // main
      heroContent={
        <HeroWrapper>
          <HeroLeft token={token} showSurvey={event.postShow?.showSurvey}>
            <Hero
              heroContent={
                <HeroSection token={token}>
                  <ThankYouHeader>
                    {token
                      ? ReactHtmlParser(event?.postShow?.thankYouHeader[0])
                      : ReactHtmlParser(event?.postShow?.thankYouHeader[1])}
                  </ThankYouHeader>
                  <ThankYouBody>
                    {token
                      ? ReactHtmlParser(event?.postShow?.thankYouBody[0])
                      : ReactHtmlParser(event?.postShow?.thankYouBody[1])}
                  </ThankYouBody>
                </HeroSection>
              }
            />
          </HeroLeft>

          {event.postShow?.showSurvey && token && (
            <HeroRight>
              <SurveyContainer>
                <PostShowSurvey
                  fields={event?.register?.fields}
                  token={token}
                />
              </SurveyContainer>
            </HeroRight>
          )}
        </HeroWrapper>
      }
      videoStyle={
        event?.show?.chatActive
          ? { position: "relative", width: "100%" }
          : undefined
      }
      heading={event?.postShow?.heading}
      subHeading={event?.postShow?.subHeading}
      body={event?.postShow?.body}
      flexGrow
      // footer
      showFooter
    />
  );
};

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.token &&
    props.showSurvey &&
    `@media only screen and ${props.theme.maxMedia.medium} {
        margin-right: 392px;
      }`}
`;

const HeroRight = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid #18436b;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 392px;
    flex-shrink: 0;
  }
`;

const SurveyContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeroSection = styled.div``;

const ThankYouHeader = styled.h1`
  font-size: clamp(32px, 8vw, 78px);
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 25px;
`;

const ThankYouBody = styled.p`
  font-size: 20px;
  color: #ffffff;
  margin: 0;

  a {
    color: #ffffff;
  }
`;
