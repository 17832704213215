import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";

export const BrandAd = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { image, link } = event.callToActions.tertiary;

  let adImage = `${api}/o/${storageDirectory}%2F${image}?alt=media`;

  const adClickHandler = () => {
    logClick({
      label: "Brand Ad",
      url: link,
    });
  };

  return (
    <BrandAdWrapper>
      <a href={link} onClick={adClickHandler}>
        <img src={adImage} />
      </a>
    </BrandAdWrapper>
  );
};

const BrandAdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
`;
