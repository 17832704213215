import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Heading } from "Components";

export const Main = ({
  heroContent,
  heading,
  subHeading,
  body,
  noPadding,
  children,
  flexGrow,
}) => {
  let showMainContainer = false;
  if (subHeading || body) showMainContainer = true;

  return (
    <MainWrapper id="main" flexGrow={flexGrow}>
      {heroContent}
      <Heading heading={heading} />

      {showMainContainer && (
        <Body id="body" noPadding={noPadding}>
          <BodyContainer>
            {subHeading && (
              <Subheading>{ReactHtmlParser(subHeading)}</Subheading>
            )}
            {body && (
              <BodySection>
                <p>{ReactHtmlParser(body)}</p>
              </BodySection>
            )}
          </BodyContainer>
        </Body>
      )}

      {!!children && children}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.flexGrow && `flex: 1;`}
`;

const Body = styled.div`
  padding: ${(props) => (props.noPadding ? 0 : "35px 15px")};
  border-top: 1px solid #d54400;
`;

const BodyContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const Subheading = styled.h4`
  font-size: clamp(24px, 5.8vw, 42px);
  margin: 0 0 16px;
  text-transform: uppercase;
`;

const BodySection = styled.div`
  font-size: 18px;

  p {
    margin-top: 0;
  }
`;
