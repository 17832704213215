import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext } from "Components";
import { Layout, BodySection } from "../Layout";
import { fonts } from "theme/fonts";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);

  return (
    <Layout preCampaign showHeader flexGrow showFooter>
      <StyledBodySection>
        <p>{ReactHtmlParser(event.preCampaign.body)}</p>
      </StyledBodySection>
    </Layout>
  );
};

const StyledBodySection = styled(BodySection)`
  padding: 15px 35px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  p {
    font-size: clamp(24px, 9vw, 64px);
    line-height: 1;
    text-transform: uppercase;
    font-family: ${fonts.header};
    margin-top: 0;
  }
`;
