import React, { createContext, useEffect, useContext } from "react";
import { EventContext, UserContext, firebase } from "Components";
import TagManager from "react-gtm-module";

export const AnalyticsContext = createContext();
export const AnalyticsConsumer = AnalyticsContext.Consumer;
export const AnalyticsProvider = ({ children }) => {
  const { event } = useContext(EventContext);
  const { user } = useContext(UserContext);

  const analytics = firebase.analytics();

  useEffect(() => {
    if (event) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: event.tracking,
      });
    }

    if (user) {
      analytics.setUserId(user.uid);
    }
  }, [user, event, analytics]);

  const logClick = ({ label, url }) => {
    logEvent({
      eventName: "external_link",
      eventParams: {
        label,
        url,
      },
    });
  };

  const logHeartbeat = ({ duration }) => {
    logEvent({
      eventName: "heartbeat",
      eventParams: {
        duration,
      },
    });
  };

  const logVideoAction = ({ bool, videoLink, videoTitle }) => {
    logEvent({
      eventName: "video",
      eventParams: {
        action: bool ? "open" : "close",
        videoTitle,
        videoLink,
      },
    });
  };

  const logLeadSubmission = ({ formPart }) => {
    logEvent({
      eventName: firebase.analytics.EventName.GENERATE_LEAD,
      eventParams: {
        formPart,
        currency: "USD",
        value: 0,
      },
    });
  };

  const logEvent = ({ eventName, eventParams = {} }) => {
    analytics.logEvent(eventName, {
      ...eventParams,
      max_mcId: event.mastercampaignId,
      max_eventId: event.id,
      max_artist: event.artist,
    });
  };

  const setUserProperties = (data = {}) => {
    analytics.setUserProperties(data);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        logEvent,
        logClick,
        logLeadSubmission,
        logVideoAction,
        logHeartbeat,
        setUserProperties,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
