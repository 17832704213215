import React, { useContext } from "react";
import styled from "styled-components";
import { Header, Main, Footer, EventContext } from "Components";

export const Layout = ({
  // header
  registration,
  preCampaign,
  showHeader,
  // main
  heroContent,
  heading,
  noPadding,
  subHeading,
  body,
  children,
  flexGrow,
  // site footer
  showFooter,
}) => {
  const { event } = useContext(EventContext);

  return (
    <SiteContainer>
      {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>}

      <Header
        showHeader={showHeader}
        registration={registration}
        preCampaign={preCampaign}
      />

      <Main
        heroContent={heroContent}
        heading={heading}
        subHeading={subHeading}
        body={body}
        noPadding={noPadding}
        children={children}
        flexGrow={flexGrow}
      />

      <Footer
        showFooter={showFooter}
        registration={registration}
        preCampaign={preCampaign}
      />
    </SiteContainer>
  );
};

// ======== SITE CONTAINER ======== //

const SiteContainer = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 2000px;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  background-color: #000000;
  color: #ffffff;

  @media ${(props) => props.theme.maxMedia.large} {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  }
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 3px 10px;
`;

// ======== VIEW TEMPLATE ======== //

export const BodySection = styled.div``;
