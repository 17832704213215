import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  firebase,
  EventContext,
  UserContext,
  AnalyticsContext,
} from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import _ from "lodash";

export const SSOButton = ({ buttonText }) => {
  const { user } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const [link, setLink] = useState();

  const [publicConfig] = useDocumentData(
    firebase.firestore().doc(`events/${event.id}/config/public`)
  );

  useEffect(() => {
    if (publicConfig) {
      createOauthLink();
    }
  }, [publicConfig]);

  const createOauthLink = () => {
    let oAuthConfig =
      process.env.NODE_ENV === "production"
        ? _.get(publicConfig, "oauth.prod")
        : _.get(publicConfig, "oauth.dev");

    const oAuthloginUrl = _.get(oAuthConfig, "loginUrl");
    const oAuthClientId = _.get(oAuthConfig, "clientId");
    const oAuthRedirectUrl = _.get(oAuthConfig, "redirectUrl");
    const oAuthScope = _.get(oAuthConfig, "scope");

    if (oAuthloginUrl) {
      let url = new URL(oAuthloginUrl);

      const stateParam = [user.uid, event.id].join("|");

      const stateParamEncoded = Buffer.from(stateParam).toString("base64");

      const params = new URLSearchParams([
        ["promo", "Max0521"],
        ["response_type", "code"],
        ["client_id", oAuthClientId],
        ["redirect_uri", oAuthRedirectUrl],
        ["scope", oAuthScope.join("+")],
        ["state", stateParamEncoded],
      ]);

      url.search = params;

      setLink(url);
    }
  };

  const handleSSOClick = async () => {
    logClick({
      label: "SSO",
      url: link.toString(),
    });
    await firebase
      .firestore()
      .doc(`events/${event.id}/oauth_logs/${user.uid}`)
      .set(
        {
          out: firebase.firestore.FieldValue.arrayUnion(new Date()),
        },
        {
          merge: true,
        }
      );

    window.location.href = link;
  };

  return (
    <>
      {link && (
        <RegisterButton onClick={handleSSOClick}>
          {buttonText ? buttonText : "Register"}
        </RegisterButton>
      )}
    </>
  );
};

const RegisterButton = styled.a`
  margin-top: 24px;
  background-color: #ea1300;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50px;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  outline: 0;
  border: 0;

  &:hover,
  &:focus {
    background-color: #b91304;
  }
`;
