import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { 0: primary, 1: host, 2: artist, 3: brand } = event.logos;

  let primaryLogo = `${api}/o/${storageDirectory}%2F${primary.path}?alt=media`;
  let hostLogo = `${api}/o/${storageDirectory}%2F${host.path}?alt=media`;
  let artistLogo = `${api}/o/${storageDirectory}%2F${artist.path}?alt=media`;
  let brandLogo = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer>
            <HeaderLeft preCampaign={preCampaign}>
              <PrimaryLogo preCampaign={preCampaign} src={primaryLogo} />

              {!preCampaign && (
                <HostLink
                  href={host.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logoClickHandler(host.link)}
                >
                  <HostLogo src={hostLogo} />
                </HostLink>
              )}
            </HeaderLeft>

            {!preCampaign && (
              <HeaderRight>
                <Link
                  href={artist.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logoClickHandler(artist.link)}
                >
                  <ArtistLogo src={artistLogo} />
                </Link>
              </HeaderRight>
            )}
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  background-color: #000000;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex: 1;
  padding: 15px 35px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)`
  ${(props) =>
    props.preCampaign
      ? `
    width: 100%;
    max-width: 150px;
  `
      : `
    width: 35%;
    max-width: 80px;

    @media only screen and ${props.theme.maxMedia.medium} {
      width: 100%;
      max-width: 150px;
    }
  `}
`;

const HostLogo = styled(Logo)`
  width: 100%;
  max-width: 225px;
`;

const HostLink = styled.a`
  width: 50%;
`;

const ArtistLogo = styled(Logo)`
  max-width: 350px;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.preCampaign
      ? `
    justify-content: start;
    width: 100%;
  `
      : `
    justify-content: space-around;

    @media only screen and ${props.theme.maxMedia.medium} {
      width: 415px;
      justify-content: space-between;
    }
  `}
`;

const HeaderRight = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    margin-left: 50px;
    padding-left: 35px;
    border-left: 1px solid white;
  }
`;
