import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "Components";
import ReactHtmlParser from "react-html-parser";

export const Rules = () => {
  const { event } = useContext(EventContext);

  return (
    <RulesWrapper>
      <Main as="main">{ReactHtmlParser(event?.rules?.body)}</Main>
    </RulesWrapper>
  );
};

const RulesWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Main = styled.main`
  padding: 0 15px;
`;
