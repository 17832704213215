import React, { useContext } from "react";
import styled from "styled-components";
import { ChatPanel, ChatBox, ChatUsername, ChatContext } from "./Components";

export const Chat = () => {
  const { setUsername, username } = useContext(ChatContext);

  return (
    <ChatWrapper>
      <ChatInner>
        <ChatPanel />
        {username ? <ChatBox /> : <ChatUsername />}
      </ChatInner>
    </ChatWrapper>
  );
};

export * from "./Components";

const ChatWrapper = styled.div`
  position: relative;
  min-height: 300px;
  background-color: #e4e4e4;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 392px;
    min-height: unset;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

const ChatInner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    overflow: hidden;
  }
`;
