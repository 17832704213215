import React from "react";
import { CookiesProvider } from "react-cookie";
import { TimeProvider, EventProvider } from "Components";
import { Routes } from "./Routes";
import "./App.css";

function App() {
  return (
    <CookiesProvider>
      <TimeProvider>
        <EventProvider>
          <Routes />
        </EventProvider>
      </TimeProvider>
    </CookiesProvider>
  );
}

export default App;
