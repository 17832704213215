import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "Components";
import { fonts } from "theme/fonts";

export const Footer = ({ showFooter, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { 0: primary, 1: host, 2: artist, 3: brand } = event.logos;

  let primaryLogo = `${api}/o/${storageDirectory}%2F${primary.path}?alt=media`;
  let hostLogo = `${api}/o/${storageDirectory}%2F${host.path}?alt=media`;
  let artistLogo = `${api}/o/${storageDirectory}%2F${artist.path}?alt=media`;
  let brandLogo = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const minimal = registration || preCampaign;

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" minimal={minimal}>
          <FooterContainer minimal={minimal}>
            {!minimal && (
              <Link
                href={brand.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => logoClickHandler(brand.link)}
              >
                <Logo src={brandLogo} />
              </Link>
            )}

            {registration && event.footer.sweepsDisclaimer && (
              <SweepsDisclaimer>
                {ReactHtmlParser(event.footer.sweepsDisclaimer)}
              </SweepsDisclaimer>
            )}

            <MaxPolicy>{ReactHtmlParser(event.footer.maxPolicy)}</MaxPolicy>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  color: #ffffff;
  background-color: #000000;
  ${(props) => !props.minimal && `border-top: 1px solid #d54400;`}

  a,
  a:visited {
    color: #ffffff;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-family: ${fonts.default};
  align-items: center;
  text-align: center;
  padding: 15px 35px;

  ${(props) =>
    props.minimal &&
    `align-items: start;
    text-align: left;`}
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
`;

const SweepsDisclaimer = styled.p``;
const MaxPolicy = styled.p``;
