import React from "react";
import styled from "styled-components";

export const Hero = ({ heroContent = false }) => {
  return (
    heroContent && (
      <HeroWrapper id="hero">
        <HeroContainer>
          <HeroContent>{heroContent}</HeroContent>
        </HeroContainer>
      </HeroWrapper>
    )
  );
};

const HeroWrapper = styled.div`
  display: flex;
  padding: 0 15px;
`;

const HeroContainer = styled.div`
  height: 56.25vw;
  max-height: 500px;
  position: relative;
  width: 100%;
  display: flex;
  max-width: 900px;
  margin: 0 auto;
`;

const HeroContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .vimeo {
    width: 100%;
  }
`;
