import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { MAXTheme } from "notes";
import { theme } from "theme";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { MessageProvider, UserProvider, AnalyticsProvider } from "Components";
import { Root } from "./Root";
import { Reminder } from "./Reminder";
import { Calendar } from "./Calendar";
import { Rules } from "./Rules/Rules";

const mergedTheme = {
  ...MAXTheme,
  ...theme,
};

const getBasename = () => {
  let base = "/";

  const tags = document.getElementsByTagName("base");
  if (tags.length > 0) {
    base = tags[0].getAttribute("href") || base;
    try {
      base = new URL(base).pathname;
    } catch (error) {
      // pass
    }
  }

  return base;
};

export const Routes = () => {
  const [basename] = useState(getBasename());
  return (
    <ThemeProvider theme={mergedTheme}>
      <UserProvider>
        <MessageProvider>
          <AnalyticsProvider>
            <Router basename={`${basename}`}>
              <Switch>
                <Route exact path="/rules" component={Rules} />
                <Route exact path="/reminder" component={Reminder} />
                <Route exact path="/calendar/:type" component={Calendar} />
                <Route path="/" component={Root} />
                <Redirect to="/" />
              </Switch>
            </Router>
          </AnalyticsProvider>
        </MessageProvider>
      </UserProvider>
    </ThemeProvider>
  );
};
