import React from "react";
import styled from "styled-components";
import { fonts } from "theme/fonts";

export const Countdown = ({ label, days, hours, minutes, seconds }) => {
  return (
    <CountdownContainer>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Outline>
            <Increment>{days}</Increment>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
        <Outline>
          <Increment>{hours}</Increment>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Outline>
        <Outline>
          <Increment>{minutes}</Increment>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Outline>
        {days < 1 && (
          <Outline>
            <Increment>{seconds}</Increment>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CountdownTitle = styled.h3`
  font-size: clamp(32px, 9vw, 65px);
  line-height: 1;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;

const Increment = styled.div`
  font-weight: 700;
  font-size: clamp(32px, 14vw, 100px);
  line-height: 1;
`;

const Label = styled.div`
  font-size: clamp(12px, 3vw, 21px);
  text-transform: uppercase;
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
`;
