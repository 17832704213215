import React from "react";
import styled from "styled-components";

export const CountdownSmall = ({ label, days, hours, minutes, seconds }) => {
  return (
    <>
      <CountdownContainer xCenter>
        <CountdownTitle>{label}</CountdownTitle>
        <GridRow>
          {days >= 1 && (
            <Outline xCenter>
              <Increment>{days}</Increment>
              <Label>Day{days !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
          <Outline xCenter>
            <Increment>{hours}</Increment>
            <Label>Hour{hours !== 1 ? "s" : ""}</Label>
          </Outline>
          <Outline xCenter>
            <Increment>{minutes}</Increment>
            <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
          </Outline>
          {days < 1 && (
            <Outline xCenter>
              <Increment>{seconds}</Increment>
              <Label>Second{seconds !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
        </GridRow>
      </CountdownContainer>
    </>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CountdownTitle = styled.h3`
  font-size: clamp(28px, 9vw, 48px);
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin-bottom: 15px;
  }
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;

const Increment = styled.div`
  font-weight: 700;
  font-size: clamp(62px, 13vw, 72px);
  line-height: 1;
`;

const Label = styled.div`
  font-size: clamp(12px, 3vw, 18px);
  text-transform: uppercase;
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  width: 30%;
`;
