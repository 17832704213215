import { useContext, useEffect } from "react";
import { TimeContext, AnalyticsContext } from "Components";

export const Heartbeater = () => {
  const { time } = useContext(TimeContext);
  const { logHeartbeat } = useContext(AnalyticsContext);

  useEffect(() => {
    if (time.second === 0) {
      logHeartbeat({ duration: "1min" });
    }
  }, [time, logHeartbeat]);

  return null;
};
