import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, Label } from "notes";
import { Field } from "Components";
import ReactHtmlParser from "react-html-parser";

export const FormFields = ({
  fields,
  formPart,
  handleChange,
  errors,
  registration,
  formLegal,
}) => {
  return (
    <Form>
      {fields &&
        fields
          .sort((a, b) => a.order - b.order)
          .filter((field) => {
            return field.formPart === formPart;
          })
          .map((field, index) => {
            return (
              <FieldContainer
                fullSize={field.span === "full"}
                key={index}
                order={field.order}
              >
                {field.type !== "optIn" && !!field.label && (
                  <Label>{field.label}</Label>
                )}
                <Field
                  field={field}
                  fieldCheck={
                    !!field.requires ? registration[field.requires] : true
                  }
                  value={registration[field.name]}
                  onChange={(value) => handleChange(value, field.name)}
                  error={{
                    status: errors[field.name],
                    message: "This is a required field and cannot be blank.",
                  }}
                />
              </FieldContainer>
            );
          })}

      {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}
    </Form>
  );
};

const Form = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: 8px 4px 0 4px;
  width: ${(props) => (props.fullSize ? "100%" : "50%")};
  ${(props) => props.order && `order: ${props.order};`}
`;

const FormLegal = styled.div`
  color: #ffffff;
  font-size: 11px;
  line-height: 1.5;
  margin: 7px 0;
  padding: 8px 4px 0 4px;
  font-style: italic;
  order: 4;

  a,
  a:visited {
    color: #ffffff;
  }
`;
