import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "notes";
import { RegistrationForm } from "./Components";
import { Layout } from "../Layout";
import { Duration } from "luxon";
import {
  TimeContext,
  CountdownSmall as Countdown,
  AnalyticsContext,
} from "Components";
import { fonts } from "theme/fonts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay as playIcon } from "@fortawesome/free-solid-svg-icons";

export const Register = React.memo(({ event, onSuccess }) => {
  const { time } = useContext(TimeContext);
  const { logClick, logVideoAction } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [video, setVideo] = useState(null);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let desktopImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImage?.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImage?.mobile}?alt=media`;

  const countdownHeading = event.preShow.countdownLabel;

  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: countdownHeading ? countdownHeading : "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  const handleSuccess = (admin, token) => {
    onSuccess(admin, token);
  };

  // const setModalVisible = (bool) => {
  //   setVideo(video);
  //   setOpen(bool);
  //   logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
  // };

  let RegFormContent;
  let artistOverlayContent = null;

  if (event.campaignEnd < time) {
    // campaign has ended
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(event.postCampaign.heading)}</Heading>
        <p>{ReactHtmlParser(event.postCampaign.body)}</p>
      </>
    );
  } else {
    const { heading, formLegal, submit } = event.register.postSweeps[0];
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(heading)}</Heading>
        <RegistrationForm
          formPart={0}
          onSuccess={handleSuccess}
          formLegal={formLegal}
          submitButton={submit}
        />
      </>
    );

    if (cookies?.registered) {
      const { heading, body } = event.register.postSweeps[1];
      RegFormContent = (
        <>
          <ConfirmationHeading>{ReactHtmlParser(heading)}</ConfirmationHeading>
          <ConfirmationBody>{ReactHtmlParser(body)}</ConfirmationBody>
        </>
      );
      artistOverlayContent = (
        <SmallCountdown>
          <Countdown {...timer} />
        </SmallCountdown>
      );
    }
  }

  return (
    <Layout registration showHeader flexGrow showFooter>
      <Registration>
        <Content as="section" id="registration-content">
          <ContentContainer>
            <FormContent>{RegFormContent}</FormContent>

            {artistOverlayContent && (
              <OverlayContainer>
                <OverlayContent>{artistOverlayContent}</OverlayContent>
              </OverlayContainer>
            )}
          </ContentContainer>
        </Content>

        <ArtistImage
          as="section"
          id="artist-image"
          desktopImage={desktopImage}
          mobileImage={mobileImage}
        ></ArtistImage>
      </Registration>

      {/* <VideoModal size="large" open={open} setOpen={setModalVisible}>
        <VideoModalContainer>
          <Video
            src={video}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoModalContainer>
      </VideoModal> */}
    </Layout>
  );
});

// ======== CONTENT / LEFT SIDE ======== //

const Registration = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 25px 15px 50px;
  width: 100%;
  height: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  hr {
    width: 100%;
    height: 10px;
    border-width: 2px 0;
    border-style: solid;
    border-color: #d54400;
    margin: 25px 0;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 50px 35px;
    width: 450px;
  }
`;

const Link = styled.a`
  margin: 50px 0;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  p {
    margin-top: 0;
  }

  a {
    color: #ffffff;
  }
`;

const Heading = styled.h2`
  line-height: 1.25;
  margin-bottom: 8px;
  color: #ffffff;
  text-transform: uppercase;

  span {
    display: block;
    font-size: clamp(16px, 4vw, 21px);
  }

  span:nth-of-type(1) {
    font-family: ${fonts.regular};
  }

  span:nth-of-type(2) {
    font-size: clamp(48px, 7vw, 38px);
    line-height: 1;
  }

  span:nth-of-type(3) {
    font-family: ${fonts.regular};
  }
`;

const ConfirmationHeading = styled(Heading)`
  margin-bottom: 16px;

  span {
    display: block;
    font-size: clamp(16px, 4vw, 24px);
  }

  span:nth-of-type(1) {
    font-family: ${fonts.regular};
  }

  span:nth-of-type(2) {
    font-size: clamp(48px, 7vw, 38px);
    line-height: 1;
  }

  span:nth-of-type(3) {
    font-family: ${fonts.regular};
  }
`;

const ConfirmationBody = styled.p``;

const SubHeading = styled.h3`
  font-size: 34px;
  margin: 0;
`;

const ContentCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  color: #ffffff;
  text-align: center;

  h3 {
    font-size: 14px;
    text-transform: uppercase;

    span {
      font-size: 16px;
      text-transform: none;
      display: block;
    }
  }

  &:hover {
    svg {
      opacity: 1;
      filter: drop-shadow(2px 2px 10px rgb(0 0 0 / 50%));
    }
  }
`;

const VideoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('${(props) => props.background}');
  background-position: center;
  background-size: cover;
  padding-top: 56.25%;
  position: relative;
  cursor: pointer;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  opacity: 0.75;
  font-size: clamp(32px, 14vw, 92px);
  filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 50%));
  transition: all 0.25s ease-in-out;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 62px;
  }
`;

const SmallCountdown = styled.div`
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
    padding: 25px;
    width: unset;
  }
`;

const SetTheSetCTA = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  a {
    color: #d54400;
    background-color: #ffffff;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding: 10px 35px;
    font-size: clamp(18px, 3vw, 24px);
    margin-bottom: 25px;
  }

  a:hover,
  a:focus {
    color: #ffffff;
    background-color: #d54400;
  }

  h3 {
    line-height: 1.25;

    span {
      display: block;
    }

    span:nth-of-type(1) {
      font-size: clamp(11px, 4vw, 17px);
    }

    span:nth-of-type(2) {
      font-size: clamp(22px, 8vw, 34px);
    }

    span:nth-of-type(3) {
      font-size: clamp(14px, 5vw, 22px);
    }
  }
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const ArtistImage = styled.div`
  align-items: flex-start;
  background-image: url(${(props) => props.mobileImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 66.25%;
  position: relative;
  background-position: left center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktopImage});
    flex-grow: 1;
    padding-top: 0;
  }
`;

const OverlayContainer = styled.div`
  position: relative;
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 25px 0 0;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 50%);

  h1 {
    font-size: clamp(38px, 7vw, 100px);
    margin-bottom: 0;
    line-height: 1.25;
  }

  h2 {
    font-size: clamp(16px, 2.5vw, 38px);
    margin-bottom: 0;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    align-items: flex-end;
    justify-content: center;
  }
`;

const OverlayImage = styled.img`
  width: clamp(50px, 15vw, 100px);
  cursor: pointer;
`;

// ======== MODAL ======== //

const VideoModal = styled(Modal)`
  justify-content: center;
  min-height: unset;
  position: relative;

  svg {
    top: 10px;
    right: 10px;
  }
`;

const VideoModalContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
`;

const Video = styled.iframe`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border: 0;
`;
