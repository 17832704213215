import React, { forwardRef } from "react";
import styled from "styled-components";
import { fonts } from "../../theme/fonts";

export const Input = forwardRef((props, ref) =>
  (({
    onChange,
    value,
    placeholder,
    error,
    disabled,
    InternalField = TextField,
    placeholderStyle,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { value },
      } = e;
      return onChange(value, e);
    };
    return (
      <TextContainer error={error} disabled={disabled}>
        <Placeholder visible={value}>{placeholder}</Placeholder>
        <InternalField
          onChange={handleChange}
          value={value}
          error={error}
          disabled={disabled}
          {...props}
        />
      </TextContainer>
    );
  })({ ...props, ref })
);

const TextContainer = styled.div`
  background-color: ${(props) => (props.disabled ? "#A6AEB2" : "#000000")};
  display: block;
  position: relative;
  flex-grow: 1;
  ${(props) => props.error && `background-color: #EDB8B4;`}
`;

const Placeholder = styled.div`
  align-items: center;
  color: #ffffff;
  display: ${(props) => (props.visible ? "none" : "flex")};
  justify-content: left;
  padding: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  font-weight: 300;
  font-size: 12px;
  top: 0;
  bottom: 0;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 16px;
  }
`;

const TextField = styled.input`
  background-color: transparent;
  border: 1px solid #626466;
  border-radius: 0;
  color: #ffffff;
  display: block;
  outline: 0;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;

  &:focus {
    border: 1px solid #626466;
  }
  &:disabled {
    border: 1px solid #626466;
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid #E45C52;

    &:focus {
      border: 2px solid #E45C52;
    }`};

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: 16px;
  }
`;
