import React, { useContext } from "react";
import { Duration } from "luxon";
import { TimeContext, Countdown, Hero } from "Components";
import { Layout } from "../Layout";

export const PreShow = ({ event }) => {
  const { time } = useContext(TimeContext);

  const timeToStart =
    time < event?.start &&
    Duration.fromMillis(event?.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: event?.preShow?.countdownLabel ?? "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      // header
      showHeader
      // main
      heroContent={<Hero heroContent={<Countdown {...timer} />} />}
      heading={event?.preShow?.heading}
      subHeading={event.preShow?.subHeading}
      body={event?.preShow?.body}
      flexGrow
      // footer
      showFooter
    />
  );
};
