import React from "react";
import { Video, VideoChat, Heartbeater } from "Components";
import { Layout } from "../Layout";
import { AuthAccess } from "services";

export const Show = ({ event, token }) => {
  return (
    <>
      <Layout
        // header
        showHeader
        // main
        heroContent={
          event?.show?.chatActive ? (
            <VideoChat
              videoId={event?.show?.videoId}
              chatId={event?.show?.chatId}
              videoMeta={false}
            />
          ) : (
            <Video id={event?.show?.videoId} />
          )
        }
        heading={event?.show?.heading}
        subHeading={event.show?.subHeading}
        body={event?.show?.body}
        flexGrow={false}
        // footer
        showFooter
      />
      <AuthAccess token={token} />
      <Heartbeater />
    </>
  );
};
