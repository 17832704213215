import React from "react";
import styled from "styled-components";
import { Video } from "Components";
import { Chat as ChatComponent, ChatProvider } from "Components";

export const VideoChat = ({ videoId, chatId, videoMeta, ...props }) => {
  return (
    <ChatProvider>
      <VideoChatContainer>
        <VideoContainer>
          <Video id={videoId} />
          {videoMeta}
        </VideoContainer>
        <ChatComponent />
      </VideoChatContainer>
    </ChatProvider>
  );
};

const VideoChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    height: unset;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  background-color: #151515;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin-right: 392px;
  }
`;
